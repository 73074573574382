:root {
  --staff-radio-primary: #ffc107;
  --staff-radio-secondary: #7c3ab7;
  --payment-radio-primary: #ffc107;
  --payment-radio-secondary: #3f51b5;
  --admin-radio-primary:#ffc107;
  --admin-radio-secondary: #2196f3;
  /* --admin-radio-secondary: lightgray; */
  /* --admin-radio-secondary: #b4c468; */
}

html,
body {
  height: 100%;
  margin: 0;
}

.app {
  text-align: center;
  background-color: lightgrey;
  padding: 10px;
  display: flex;
  flex-flow: column;
  height: 100vh;
}

.main-container {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.header-wrapper {
  display: flex;
  justify-content: space-between;
}

.header {
  flex: 0 1 auto;
  margin-bottom: 10px;
  background-color: white;
  height: 60px;
  padding: 0
}

.header-buffer {
  display: flex;
  flex-direction: row-reverse;
  width: 100px;
}

.header-link {
  margin: 25px 22px 0 0;
  font-size: 14px;
  font-weight: bold;
  color: grey;
  cursor: pointer;
  font-style: italic;
}

.header-text {
  font-size: 36px;
  font-weight: bold;
  color: grey;
  margin-top: 8px;
}

.columns-container {
  display: flex;
  flex-flow: row;
  flex: 1 1 auto;
  overflow: hidden;
  padding-bottom: 10px;
}

.first-column {
  margin-bottom: 10px;
  margin-right: 10px;
  background-color: white;
  overflow: auto;
  padding: 5px 5px 5px 10px;
}

.second-column {
  margin-bottom: 10px;
  overflow: auto;
}

.box-row {
  display: flex;
  flex-flow: row wrap;
}

.card {
  flex-basis: 150px;
  flex-grow:1;
  height: 132px;
  margin: 0 2px 2px 2px;
  background-color: #3f51b5;
  cursor: pointer;
  border-radius: 12px;
  border-bottom: gray;
  border-right: gray;
}

.hidden-card {
  flex-basis: 150px;
  flex-grow:1;
  height: 150px;
  margin-bottom: 2px;
}

.card:hover {
  -webkit-box-shadow: 0 0 10px 3px grey;
          box-shadow: 0 0 10px 3px grey;
  -webkit-transition: .2s;
  transition: .2s;
  opacity: .75;
}

.thai-label {
  display: flex;
  color: white;
  font-weight: bold;
  font-size: 32px;
  justify-content: center;
  margin-bottom: 10px;
}

.english-label {
  display: flex;
  color: white;
  font-weight: normal;
  font-size: 16px;
  font-style: italic;
  justify-content: center;
}

div.buttons-wrapper {
  margin-bottom: 25px;
}

/* Hide inputs */
input.staff-radio-input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.staff-radio-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-flow: row wrap;
  color: var(--staff-radio-primary);
  background: var(--staff-radio-secondary);
  opacity: .85;
  border-radius: 25px;
  width: 100%;
  padding: 8px 10px 0px 10px;
  box-sizing: border-box;
  margin-bottom: 0px;
}

label.staff-radio-label {
  display: flex;
  align-items: center;
  padding: 1rem 1rem .2rem 2rem;
  position: relative;
  font-size: 22px;
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
  transition: all 0.25s cubic-bezier(0.25, 0.25, 0.5, 1.9);
  top: -10px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 0.75rem;
    height: 0.75rem;
    background: transparent;
    border: 3px solid;
    border-radius: 2.0rem;
    z-index: -1;
    transition: all 0.25s cubic-bezier(0.25, 0.25, 0.5, 1.9);
  }
}

input.staff-radio-input:checked + .staff-radio-label {
  padding-left: 1em;
  color: var(--staff-radio-secondary);
  &::before {
    width: 100%;
    height: 35px;
    background: var(--staff-radio-primary);
    border: 0;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  }
}

input.payment-radio-input {
  position: absolute;
  opacity: 0;
  z-index: -1;
}

.payment-radio-group {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-flow: row wrap;
  color: var(--payment-radio-primary);
  background: var(--payment-radio-secondary);
  opacity: .85;
  border-radius: 25px;
  height: 40px;
  width: 70%;
  padding: 10px;
  margin: 7px 15% -10px 15%;
  box-sizing: border-box;
}

label.payment-radio-label {
  display: flex;
  align-items: center;
  padding: 1rem 1rem 1rem 2rem;
  position: relative;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  z-index: 1;
  transition: all 0.25s cubic-bezier(0.25, 0.25, 0.5, 1.9);
  top: -18px;
  &::before {
    content: "";
    position: absolute;
    left: 0;
    width: 0.75rem;
    height: 0.75rem;
    background: transparent;
    border: 3px solid;
    border-radius: 2.0rem;
    z-index: -1;
    transition: all 0.25s cubic-bezier(0.25, 0.25, 0.5, 1.9);
  }
}

input.payment-radio-input:checked + .payment-radio-label {
  padding-left: .8em;
  color: var(--payment-radio-secondary);
  &::before {
    width: 100%;
    height: 25px;
    background: var(--payment-radio-primary);
    border: 0;
    box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
  }
}

input.admin-radio-input {
	position: absolute;
	opacity: 0;
	z-index: -1;
}
  
.admin-radio-group {
	display: flex;
	flex-direction: row;
	justify-content: space-evenly;
	flex-flow: row wrap;
	color: var(--admin-radio-primary);
	background: var(--admin-radio-secondary);
	opacity: .85;
	border-radius: 25px;
	height: 50px;
	width: 600px;
	padding: 10px;
	box-sizing: border-box;
	margin-top: 5px;
	margin-bottom: 0px;
}
  
label.admin-radio-label {
	display: flex;
	align-items: center;
	padding: 1rem 1rem 1rem 2rem;
	position: relative;
	font-size: 20px;
	font-weight: bold;
	cursor: pointer;
	z-index: 1;
	transition: all 0.25s cubic-bezier(0.25, 0.25, 0.5, 1.9);
	top: -12px;
	&::before {
	  content: "";
	  position: absolute;
	  left: 0;
	  width: 0.75rem;
	  height: 0.75rem;
	  background: transparent;
	  border: 3px solid;
	  border-radius: 2.0rem;
	  z-index: -1;
	  transition: all 0.25s cubic-bezier(0.25, 0.25, 0.5, 1.9);
	}
}
  
input.admin-radio-input:checked + .admin-radio-label {
	padding-left: 1em;
	color: var(--staff-radio-secondary);
	&::before {
	  width: 100%;
	  height: 35px;
	  background: var(--staff-radio-primary);
	  border: 0;
	  box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.5);
	}
}
	
.price-row {
  display: flex;
  margin: 15px 10px 0 10px;
  background: lightgrey;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  padding: 5px 0 2px 2px;
  height: 35px;
}

.totals-row {
  margin: 0px 10px 20px 10px;
}

input.submit-row {
  color: #3f51b5
}

span.submit-row {
  font-weight: bold;
  color: #3f51b5;
}

input.staff-totals {
  color: #287e29
}

span.staff-totals {
  font-weight: bold;
  color: #287e29;
}

input.grand-totals {
  color: #7c3ab7
}

span.grand-totals {
  font-weight: bold;
  color: #7c3ab7;
}

.price-input {
  width: 55px;
  text-align: right;
  font-size: 15px;
  font-weight: bold;
  margin: 5px 10px 7px 10px;
  padding: 7px;
  border: none;
  outline: none;
  color: #2c3e50;
  border-radius: 0.25rem;
  box-shadow:  0 2px 4px rgba(0,0,0, .2);
}

.price-input:focus{
  border: 1px solid lightgrey;
}

.trash-icon {
  display: flex;
  align-self: flex-end;
  color: red;
  margin: auto 10px auto auto;
  right: 0px;
  cursor: pointer;
}

.price-label-thai {
  margin-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
  font-size: 16px;
}

.submit-switch-date {
  margin: auto;
  font-size: 12px;
  background-color: #E5E5E5;
  padding: 5px;
  cursor: pointer;
}

.price-label-english {
  margin-left: 5px;
  margin-top: 12px;
  margin-bottom: auto;
  font-size: 12px;
  &::before {
    content: "(";
  };
  &::after {
    content: ")";
  };
}

.price-input:hover, .price-input:focus{
  box-shadow: 0 4px 6px rgba(0,0,0, .3);
}

.submit-sale {
  color: white;
  font-size: 15px;
  font-weight: bold;
  width: 85px;
  height: 80%;
  margin: auto 10px auto auto;
  background-color: #287e29;
  cursor: pointer;
  border-radius: 12px;
  border: 0;
}

.sales-container {
  display:flex;
}

.sale-container {
  flex-grow: 1;
  background-color: white;
  overflow: auto;
  padding: 10px;
}

.totals-container {
  display: flex;
  border-top: 10px solid lightgrey;
  box-sizing: border-box;
  overflow: auto;
}

.total-container {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
}

.admin-column {
  flex-basis: 100%;
  margin-bottom: 10px;
  margin-right: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.admin-wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	flex-basis: 100%;
}

.data-wrapper {
	border-right: 10px solid lightgrey;
	padding: 0 10px 0 10px;
	overflow: auto;
	flex: 1 0;
}

.select-wrapper {
	flex: 0 1 15px;
	background-color: white;
	padding: 7px;
	border-right: 10px solid lightgrey;
	text-align: left;
}

.spacer-div {
	flex: 1 0;
	height: 10px;
}

.commission-title {
	font-size: 22px;
	margin-top: 25px;
	margin-bottom: 15px;
}

.commission-row {
	display: flex;
	justify-content: space-evenly;
	border-top: thin solid rgba(0,0,0,.12);
	height: 45px;
}

.commission-text {
	display: flex;
	align-self: center;
	margin: 14px 0px auto 15px;
	font-weight: normal;
	font-size: 14px;
	white-space: pre;
}

.commission-submit-row {
	display: flex;
	flex-direction: column;
	border-top: thin solid rgba(0,0,0,.12);
	padding: 0px;
	margin-bottom: 10px;
}

.commission-submit-container {
	border-top: thin solid rgba(0,0,0,.12);
	margin-top: "20px";
	display: flex;
	justify-content: space-evenly;
}

.commission-totals-label {
	margin-left: 5px;
	margin-top: auto;
	margin-bottom: auto;
	font-size: 16px;
	font-weight: bold;
}

.commission-totals-amount {
	font-size: 16px;
	font-weight: normal;
}

.commission-input {
	width: 80px;
	text-align: center;
	font-size: 15px;
	margin: 7px 10px 0 0px;
	padding: 7px;
	border: none;
	outline: none;
	color: #2c3e50;
	border-radius: 0.25rem;
	box-shadow:  0 2px 4px rgba(0,0,0, .2);
}

.service-card {
	flex-basis: 150px;
	flex-grow:1;
	height: 132px;
	margin: 0 2px 2px 2px;
	background-color: #3f51b5;
	cursor: pointer;
	border-radius: 12px;
	border-bottom: gray;
	border-right: gray;
}

.service-input {
	width: 65%;
	text-align: center;
	/* font-size: 24px; */
	padding: 7px;
	margin-bottom: 12px;
	border: none;
	outline: none;
	color: #2c3e50;
	border-radius: 0.25rem;
	box-shadow:  0 2px 4px rgba(0,0,0, .2);
}

.react-datepicker__input-container input {
	width: 105px;
	height: 30px;
	margin: 7px 10px 0 10px;
	text-align: center;
	font-size: 15px;
	border: none;
	outline: none;
	color: #2c3e50;
	border-radius: 0.25rem;
	box-shadow:  0 2px 4px rgba(0,0,0, .2);
}

.submit-commission {
	color: white;
	font-size: 15px;
	font-weight: bold;
	margin: 10px auto auto auto;
	background: green;
	padding: 5px 12px 5px 12px;
	cursor: pointer;
	border-radius: 12px;
	border: 0;
}

.commission-footer {
	flex: 0 1 0px;
	background-color: white;
	border-bottom: 10px solid lightgrey;
}

.password-row {
	display: flex;
	margin: 0px 10px 0 10px;
	background: lightgrey;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
	padding: 5px 15px 2px 15px;
	height: 45px;
}

.password-label {
	margin-left: 5px;
	margin-top: 12px;
	margin-bottom: auto;
	font-size: 15px;
}
  
span.submit-password {
	font-weight: bold;
	color: #3f51b5;
}

.password-input {
	width: 120px;
	font-size: 14px;
	margin: 5px 10px 7px 10px;
	padding: 7px;
	border: none;
	outline: none;
	border-radius: 0.25rem;
	box-shadow:  0 2px 4px rgba(0,0,0, .2);
}

button.submit-password {
	color: white;
	font-size: 14px;
	font-weight: bold;
	width: 60px;
	margin: auto 10px auto 10px;
	background-color: #287e29;
	cursor: pointer;
	border-radius: 12px;
	border: 0;
}
  	
.admin-staff-container {
	margin: 15px 10px 0 10px;
	background: lightgrey;
	box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
	padding: 10px;
}
  	 	
.admin-staff-row {
	display: flex;
	flex-direction: row;
}

.admin-input-container {
	display: flex;
	flex-direction: column;
	flex-grow:1;
	margin-top: 5px;
}

.admin-staff-label {
	align-self: flex-start;
	margin-left: 5px;
	margin-top: 5px;
	font-size: 16px;
}

.admin-staff-input {
	font-size: 15px;
	margin: 7px 10px 7px 5px;
	padding: 7px;
	border: none;
	outline: none;
	color: #2c3e50;
	border-radius: 0.25rem;
	box-shadow:  0 2px 4px rgba(0,0,0, .2);
}

.admin-staff-button {
	color: white;
	font-size: 14px;
	font-weight: bold;
	width: 140px;
	height: 80%;
	margin: auto;
	background-color: darkgreen;
	cursor: pointer;
	border-radius: 12px;
	border: none;
  }

  button.admin-staff-button:hover {
	background-color: #287e29;
  }

.admin-reset-button {
	color: white;
	font-size: 14px;
	font-weight: bold;
	width: 140px;
	height: 80%;
	margin: auto;
	background-color: slategrey;
	cursor: pointer;
	border-radius: 12px;
	border: none;
}

button.admin-reset-button:hover {
	background-color: darkgrey;
}

.editable-service-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 45%;
	margin-bottom: 50%;
}

.editable-service-card {
	margin-left: auto;
	margin-right: auto;
	padding-top: 35px;
	width: 70%;
	height: 200px;
	margin: 0 2px 2px 2px;
	border-radius: 12px;
	border-bottom: gray;
	border-right: gray;
}

div.edit-service-buttons {
	margin-top: 10px;
	height: 25px;
	display: flex;
	justify-content: center;
}

button.edit-service-buttons {
	cursor: pointer;
	margin-right: 20px;
	width: 47px;
	height: 100%;
	font-weight: bold;
}

.add-new-service-button {
	font-size: 55px;
	color: darkgrey;
	cursor: pointer;
	margin-top: 20px;
}

.expenses-submit-container {
	border-top: thin solid rgba(0,0,0,.12);
	margin-top: "20px";
	display: flex;
	justify-content: space-evenly;
	/* align-items: stretch; */
}

.expenses-entry {
	flex: 2 1;
	align-items: stretch;
	display: flex;
	align-items: stretch;
}

.expenses-input {
	width: 100%;
	text-align: center;
	font-size: 15px;
	border: none;
	outline: none;
	color: #2c3e50;
	border-radius: 0.25rem;
	box-shadow:  0 2px 4px rgba(0,0,0, .2);
}

.datepicker-wrapper {
	padding: 0;
	width: 100%;
	display: flex;
	flex-direction: row;
	box-shadow:  0 2px 4px rgba(0,0,0, .2);
}

.datepicker-input {
	background-color: transparent;
	border: 0;
	border-radius: 4px;
	cursor: pointer;
	font: inherit;
	height: 100%;
	align-items: stretch;
	flex-grow: 1;
}
